import React from "react";
import { graphql } from "gatsby";
import * as _ from "./config/fragments";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  AboutSubHeader,
  AboutWhatSection,
  CTASection,
  StandOutSection,
} from "../components/blocks";

export default function AboutPage(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.allSanityAboutPage.edges[0].node;

  const services = [
    {
      title: pageData.whatWeDo?.service1?.title?.localized,
      description: pageData.whatWeDo?.service1?.description?.localized,
      iconUrl: pageData.whatWeDo?.service1?.iconImage.asset?.url,
    },
    {
      title: pageData.whatWeDo?.service2?.title?.localized,
      description: pageData.whatWeDo?.service2?.description?.localized,
      iconUrl: pageData.whatWeDo?.service2?.iconImage.asset?.url,
    },
    {
      title: pageData.whatWeDo?.service3?.title?.localized,
      description: pageData.whatWeDo?.service3?.description?.localized,
      iconUrl: pageData.whatWeDo?.service3?.iconImage.asset?.url,
    },
  ];

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}
      contact={sharedData.contact}>
      <SEO title={pageData.pageTitle?.localized} />
      <AboutSubHeader
        title={pageData.header?.title?.localized}
        subTitle={pageData.header?.subTitle?.localized}
        description={pageData.header?.description?.localized}
        coverImageUrl={pageData.header?.coverImage?.asset?.url}
        videoLink={pageData.header?.videoLink}
        showVideo={pageData.header?.showVideo}
      />
      <StandOutSection
        title={pageData.textBlock.title?.localized}
        subTitle={pageData.textBlock.subTitle?.localized}
        descriptionRichText={pageData.textBlock.descriptionRichText}
        coverImageUrl={pageData.textBlock.mainImage?.asset?.url}
        sectionColor={theme.colorsTw.bg_bg2}
        isFlipped={pageData.textBlock.isFlipped ?? false}
      />
      <AboutWhatSection
        title={pageData.whatWeDo?.leadText?.title?.localized}
        description={pageData.whatWeDo?.leadText?.description?.localized}
        services={services}
      />
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        callToAction={sharedData.callToActionSection?.callToAction}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($locale: String) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...SharedSections
        }
      }
    }
    # Page
    allSanityAboutPage {
      edges {
        node {
          pageTitle {
            localized(locale: $locale)
          }
          header {
            ...SubHeader
          }
          textBlock {
            ...TextBlockSection
          }
          whatWeDo {
            ...WhatSection
          }
        }
      }
    }
  }
`;
